import Vue from "vue";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import { mapGetters } from "vuex";
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("equipments.create.title"),
            id: this.$route.params.id,
            equipment: {},
            offices: [],
            validationOptions: {
                rules: {
                    officeId: {
                        required: true
                    },
                    name: {
                        required: true,
                        rangeLength: [3, 50]
                    },
                    quantity: {
                        required: true,
                        number: true,
                        min: 0
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId"
        })
    },
    methods: {
        create() {
            return this.$store.dispatch("equipment/create", {
                equipment: this.equipment
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("equipments.create.toastSuccessTitle"), this.$t("equipments.create.toastSuccessContent", {
                name: this.equipment.name
            }));
            return this.$router.push("/administration/equipments");
        },
        cancel() {
            return this.$router.push("/administration/equipments");
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.offices = await this.$store.dispatch("office/getAll");
            if (!this.hasMultipleOffices)
                this.equipment.officeId = this.getSingleOfficeId;
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/administration/equipments");
        }
        finally {
            this.hideSpinner();
        }
    }
});
